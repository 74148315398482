<template>
  <div>

    <titulo-list-add-new
      :is-add-new-titulo-sidebar-active.sync="isAddNewTituloSidebarActive"
      :situacao-options="situacaoOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <titulo-list-filters
      :situacao-filter.sync="situacaoFilter"
      :situacao-options="situacaoOptions"
      :venc-inicial-filter.sync="vencInicialFilter"
      :venc-final-filter.sync="vencFinalFilter"
      :valor-minimo-filter.sync="valorMinimoFilter"
      :valor-maximo-filter.sync="valorMaximoFilter"
      :tem-telefone-filter.sync="temTelefoneFilter"
      :tem-email-filter.sync="temEmailFilter"
    />

    <b-card
      class=""
    >
      <div class="demo-inline-spacing">
        <b-button
          variant="primary"       
          @click="negativarTitles"   
        >
          Alterar títulos para Negativado
          <feather-icon
            icon="CheckIcon"
            class="ml-50"
          />
        </b-button>

         <b-button
          variant="success"       
          @click="markPaidTitles"   
        >
          Marcar títulos como pago
          <feather-icon
            icon="CheckIcon"
            class="ml-50"
          />
        </b-button>

        <b-button
          variant="outline-success"
          class="ml-md-1"
          v-b-modal.modal-export
        >
          <span class="text-nowrap">Exportar Planilha de títulos pagos</span>
        </b-button>

        <b-modal
            id="modal-export"
            cancel-variant="outline-secondary"
            ok-variant="primary"
            ok-title="Confirmar"
            cancel-title="Cancelar"
            centered
            title="Confirmar a exportação de relatório em excel"
            @ok="exportarRelatorio"
          >            
          </b-modal>
      </div>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 select-size-sm"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Procurar..."
              />
              <!-- <b-button
                variant="primary"
                @click="isAddNewTituloSidebarActive = true"
              >
                <span class="text-nowrap">Adicionar Titulo</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refTituloListTable"
        class="position-relative"
        responsive
        striped
        hover
        :items="fetchTitulos"
        :fields="tableColumns"  
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >

        <template #cell(#)="data">
          <b-form-checkbox
            v-model="selected"
            :value="data.item.id"
            class="custom-control-primary"
          >              
          </b-form-checkbox>
        </template>

        <!-- Column: User -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.nome)"
                variant="light-danger"

              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap" 
            >
              {{ data.item.nome }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Credor -->
        <template #cell(credor)="data">
          <span class="font-weight-bolder">{{ data.item.credor }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(situacao)="data">
          <b-dropdown
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            split
            right
            size="sm"
            :text="data.item.situacao"
            :variant="resolveTituloStatusVariant(data.item.situacao)"
          >
            <b-dropdown-item @click="updateSituationTitle(data.item.id,'BAIXADO')">BAIXADO</b-dropdown-item>
            <b-dropdown-item @click="updateSituationTitle(data.item.id,'NEGATIVADO')">NEGATIVADO</b-dropdown-item>
            <b-dropdown-item @click="updateSituationTitle(data.item.id,'PRESCRITO')">PRESCRITO</b-dropdown-item>
            <b-dropdown-item @click="updateSituationTitle(data.item.id,'CADASTRADO')">CADASTRADO</b-dropdown-item>
          </b-dropdown>
          <!-- <b-badge
            pill
            :variant="`light-${resolveTituloStatusVariant(data.item.situacao)}`"
            class="text-capitalize"
          >
            {{ data.item.situacao }}
          </b-badge> -->
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item>
              <div @click="showModalDelete(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Excluir</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo de {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.total }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BDropdown, BDropdownItem, BPagination,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, computed} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import TituloListFilters from './TituloListFilters.vue'
import TituloListAddNew from './TituloListAddNew.vue'
import useTituloList from './useTituloList'
import tituloStoreModule from '../tituloStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import { useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    TituloListFilters,
    TituloListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const TITULO_APP_STORE_MODULE_NAME = 'app-titulo'

    // Register module
    if (!store.hasModule(TITULO_APP_STORE_MODULE_NAME)) store.registerModule(TITULO_APP_STORE_MODULE_NAME, tituloStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TITULO_APP_STORE_MODULE_NAME)) store.unregisterModule(TITULO_APP_STORE_MODULE_NAME)
    })

    const isAddNewTituloSidebarActive = ref(false)

    const selected  = ref([])

    const { route } = useRouter()
    const credorId = computed(() => route.value.query.credorId)

    const situacaoOptions = [
      { label: 'Negativado', value: 'NEGATIVADO' },
      { label: 'Baixado', value: 'BAIXADO' },
      { label: 'Prescrito', value: 'PRESCRITO' },
      { label: 'Cadastrado', value: 'CADASTRADO' }
    ]

    function negativarTitles(){
      if(this.selected.length > 0){
        axios.post('negativar/titulos', 
          {
            'titulos' : this.selected,
          }
        ).then((response) => {
          this.$refs.refTituloListTable.refresh();
          selected.value = []
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Alterar situação título',
              icon: 'FileIcon',
              variant: 'success',
              text: `Títulos alterados com sucesso!`,
            },
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Alterar situação título',
              icon: 'FileIcon',
              variant: 'danger',
              text: `Não conseguimos alterar seus títulos ):`,
            },
          })
        });
      }else{
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Alterar situação título',
            icon: 'FileIcon',
            variant: 'danger',
            text: `Selecione ao menos um título.`,
          },
        })
      }      
    }

    function exportarRelatorio() {
      axios.post('exportar/pagamentos')
      .then((response) => {        
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Exportação relatório',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Seu relatório será gerado!`,
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Exportação de relatório',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: `Não foi possível gerar seu relatório ):`,
          },
        })
      });
    }

    function markPaidTitles(){
      if(this.selected.length > 0){
        axios.post('pagamento/titulos', 
          {
            'titulos' : this.selected,
          }
        ).then((response) => {
          this.$refs.refTituloListTable.refresh();
          selected.value = []
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Pagamento de títulos',
              icon: 'FileIcon',
              variant: 'success',
              text: `Títulos pagos com sucesso!`,
            },
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Pagamento de títulos',
              icon: 'FileIcon',
              variant: 'danger',
              text: `Não conseguimos marcar seus títulos como pagos ):`,
            },
          })
        });
      }else{
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Pagamento de títulos',
            icon: 'FileIcon',
            variant: 'danger',
            text: `Selecione ao menos um título.`,
          },
        })
      }      
    }
    
    function updateSituationTitle(id,situation){
      axios.put('titulo/'+id+'/situacao', 
        {
          'situacao' : situation,
        }
      ).then((response) => {
        this.$refs.refTituloListTable.refresh();
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Situação do título',
            icon: 'CheckIcon',
            variant: 'success',
            text: `Situação do título alterada com sucesso!`,
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Situação do título',
            icon: 'XIcon',
            variant: 'danger',
            text: `Algo deu errado ):`,
          },
        })
      });
    }

    function showModalDelete(id) {
      this.$bvModal
        .msgBoxConfirm('Você deseja prosseguir com a exclusão?', {
          title: 'Confirme sua ação',
          size: 'sm',
          modalClass: 'modal-danger',
          okVariant: 'danger',
          okTitle: 'Sim, confirmar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value == true){
            axios.delete('titulos/'+id)
            .then((response) => {
              this.$refs.refTituloListTable.refresh();
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Exclusão de registro',
                  icon: 'FileIcon',
                  variant: 'success',
                  text: `Título excluído com sucesso!`,
                },
              })
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Exclusão de registro',
                  icon: 'FileIcon',
                  variant: 'danger',
                  text: `Não foi possível excluir seu título ):`,
                },
              })
            });
          }
        })
    }

    const {
      fetchTitulos,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      refTituloListTable,
      refetchData,

      // UI
      resolveTituloStatusVariant,

      // Extra Filters
      situacaoFilter,
      vencInicialFilter,
      vencFinalFilter,
      valorMinimoFilter,
      valorMaximoFilter,
      temTelefoneFilter,
      temEmailFilter
    } = useTituloList(credorId)

    return {

      // Sidebar
      isAddNewTituloSidebarActive,

      fetchTitulos,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      refTituloListTable,
      refetchData,

      // Filter
      selected,
      negativarTitles,
      markPaidTitles,
      avatarText,
      showModalDelete,
      updateSituationTitle,
      exportarRelatorio,

      // UI
      resolveTituloStatusVariant,

      situacaoOptions,

      // Extra Filters
      situacaoFilter,
      vencInicialFilter,
      vencFinalFilter,
      valorMinimoFilter,
      valorMaximoFilter,
      temTelefoneFilter,
      temEmailFilter,
      credorId
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
