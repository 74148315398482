import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTituloList(credorId) {
  // Use toast
  const toast = useToast()

  const refTituloListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    '#',
    { key: 'nome', label: "Devedor" },
    { key: 'numero_titulo' },
    { key: 'valor' },
    { key: 'vencimento' },
    { key: 'referencia' },
    { key: 'situacao' },
    { key: 'credor', label: "Credor" },
    { key: 'actions', label: "Ações" },
  ]
  const perPage = ref(15)
  const currentPage = ref(1)
  const perPageOptions = [10, 15, 25, 50, 100]
  const searchQuery = ref('')
  const situacaoFilter = ref(null)
  const vencInicialFilter = ref(null)
  const vencFinalFilter = ref(null)
  const valorMinimoFilter = ref(null)
  const valorMaximoFilter = ref(null)
  const temTelefoneFilter = ref(null)
  const temEmailFilter = ref(null)

  const dataMeta = ref({})

  const refetchData = () => {
    refTituloListTable.value.refresh()
    // fetchTitulos()
  }

  watch([currentPage, perPage, searchQuery, situacaoFilter, vencInicialFilter, vencFinalFilter, valorMinimoFilter, valorMaximoFilter, 
    temTelefoneFilter, temEmailFilter], () => {
    refetchData()
  })

  const fetchTitulos = (ctx, callback) => {
    store
      .dispatch('app-titulo/fetchTitulos', {
        busca: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        situacao: situacaoFilter.value,
        venc_inicial: vencInicialFilter.value,
        venc_final: vencFinalFilter.value,
        valor_minimo: valorMinimoFilter.value,
        valor_maximo: valorMaximoFilter.value,
        has_telefone: temTelefoneFilter.value,
        has_email: temEmailFilter.value,
        credor_id: credorId.value
      })
      .then(response => {
        const { data, meta } = response.data     
        callback(data)
        dataMeta.value = meta
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTituloStatusVariant = status => {
    if (status === 'NEGATIVADO') return 'primary'
    if (status === 'BAIXADO') return 'success'
    if (status === 'PRESCRITO') return 'danger'
    if (status === 'CADASTRADO') return 'info'
    return 'primary'
  }

  return {
    fetchTitulos,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    refTituloListTable,

    resolveTituloStatusVariant,
    refetchData,

    // Extra Filters
    situacaoFilter,
    vencInicialFilter,
    vencFinalFilter,
    valorMinimoFilter,
    valorMaximoFilter,
    temTelefoneFilter,
    temEmailFilter
  }
}
