import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTitulos(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('titulos', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCredor(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`titulos/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTitulo(ctx, tituloData) {
      return new Promise((resolve, reject) => {
        axios
          .post('titulos', tituloData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
